// THIS FILE IS GENERATED BY 'npm run set-env-variables'

export const environment = {
  production: true,

  // Configuration/Feature-switches:
  envName: '',
  useServiceWorker: true,
  useOffers: true,
  useQandAs: true,
  useQandASearch: true,
  useRegionPerLocale: false,
  useFeedbackPrompt: true,

  // Configuration of content
  localeLanguage: 'en',
  localeDir: 'ltr',
  localeAlternatives: 'en:English, nl:Nederlands, fr:Français, ar:العربية, pt:Português,  *:Other...',
  localeAlternativesExplanation: '* Translations via Google',
  appName: 'Helpful \n Information',
  appLogoUrl: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Cg fill="%23f5333f"%3E%3Cpath d="M10 0h10v30H10z" /%3E%3Cpath d="M30 10v10H0V10z" /%3E%3C/g%3E%3C/svg%3E',
  mainPageHeader: 'Help for undocumented people',
  mainPageIntroduction: 'This site is by the Netherlands Red Cross for people who are undocumented: without residence permit or BSN.\n We do not share your personal details.\n \n Find information for local services like legal support, health, and shelter.\n Need help or do you have questions? Contact the WhatsApp helpdesk of the Red Cross: [+31 970 10 28 69 64](https://wa.me/3197010286964)\n \n',
  mainPageNotification: '',
  pageAbout: '## About \n \n This website is developed by the [Netherlands Red Cross](https://www.rodekruis.nl/en/).\n \n On this website you can find information about local aid services for undocumented people and about their rights.\n The information will help to improve the access to basic needs such as medical care, shelter, food and clothing, and lower the stress over daily survival.\n \n For any questions or remarks about this website: please send a WhatsApp message to [+31 970 10 28 69 64](https://wa.me/3197010286964).\n \n',
  pagePrivacy: '## Privacy \n \n - No data is gathered that can be directly linked to a specific person or IP-address.\n - No cookies or long-term browser-storage is used to track any (returning) visit over time.\n - Only anonymous logs of visits are collected and stored for maximum 1 year in a database controlled by the [Netherlands Red Cross](https://www.rodekruis.nl/en/).\n \n --- \n \n _Last update: 2024-06-12_ \n',
  mainFooter: '<div class="d-flex d-flex-align-end d-flex-justify-between"><div class="markdown">\n\n **Helpful Information**  \n\n [About](/about)  \n\n [Privacy](/privacy)  \n\n [WhatsApp Helpdesk](https://wa.me/3197010286964)  \n\n </div><div><a href="https://www.rodekruis.nl/" target=_blank rel="author noopener noreferrer" class="d-flex text-area--level ion-padding"><img src="https://rodekruis.github.io/510-ICON-LIBRARY/Beeldmerk_van_het_Nederlandse_Rode_Kruis.svg" alt="" with=86 height=32 decoding=async loading=lazy></a></div></div>',
  errorHeader: 'Data Unavailable',
  errorMessage: 'Reach out to us at: ',
  errorContactUrl: 'https://www.rodekruis.nl/en/contact/',
  errorRetry: 'Try again?',

  // Regions:
  regionConfig: '',
  regions: 'almere, amsterdam, den-bosch, den-haag, eindhoven, leeuwarden, lelystad, nijmegen, rotterdam, tilburg, utrecht,',
  regionsLabels: 'Almere, Amsterdam, Den Bosch, Den Haag, Eindhoven, Leeuwarden \n(Friesland), Lelystad, Nijmegen, Rotterdam, Tilburg, Utrecht,',
  regionsSheetIds: '1mod8O9EgvqpCN3ScyAKkOh34x3OGa-8yIc18K_t1xXU, 1OANMuGJxGBQ2ba3xVfKvTpD0EGQM-ukUrgRyN9tzMbQ, 1NIFI3qUv3kpeOxGKF94BB4ZBCjm9isHMyi2M9Y-Id3s, 1m43Tw7CWRUdPjaStq6dOi9yvHSYInP1lWjLetk1PrTI, 1OhMLlM6NFD7GkoQEYMP4lnI0FKd__PKdPvYw0x57l8k, 136iThtEhGoMMVFeuMJZdVSGDKFE6XyCcMWfHRhngGjI, 1ASaVCURxA7vLmc3Vp5NvoEkR58Mc53R35jTVp7GkxM0, 1lWUjA8_sqmY7JRmYOBWn3wfRk2whR-2soZ8qfn_Fdaw, 18qD1PGTK2jTExpVIXXWpjHdpe5KDy7IdcR537CusCLU, 1gdrLrkfiElxNTHwYwV4F8FQDNNQPC6JF9cXhY8b7Los, 1ZqizriL_RJ2dCcNVnCj0GM02pqOLWcIF18A4zVigsrU,',

  // Third-party tokens:
  // Google Sheets API:
  google_sheets_api_key: 'AIzaSyDCueUJ2O_xRYtfH3Gvy4ldM3jBwCk4UtY',
  google_sheets_api_url: 'https://sheets.googleapis.com/v4/spreadsheets',

  // Application Insights
  appInsightsConnectionString: 'InstrumentationKey=67821549-12f1-4e0a-8fad-7694fe2715e3;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=10d20149-da09-45c0-9bd2-7e5290daf8a5',
};
